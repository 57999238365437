import React, { useContext } from "react"
import "./JobTop.scss"
import locationIcon from "../../assets/images/location.png"
import arrowLocIcon from "../../assets/images/arrow-location.png"
import { Link } from "gatsby"
import { LANGUAGES } from "../../constants"
import { LanguageContext } from "../../contexts/languageContext"
import { translateText } from "../../utils/translateText"
import parse from "html-react-parser"


const JobTop = ({data, imageFormatName }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]
  const routePrefix =
    currentLanguage.label === LANGUAGES.ENGLISH.label ? "" : "/de";
    const { image } = data
    const { formats, alternativeText, staticImages } = image[0]
  return  <div className = "top-component" >
              <div className = "top-content-inner" >
                        <div className="top-component-text">
                           <Link
                            className = "btn-back"
                            to={`${routePrefix}/job-listing`}
                           >
                           {`${translateText(
                                "Back",
                                currentLanguage.fullTitle,
                                googleSheetData
                              )}`}
                           </Link>
                            <h1 className = "top-component-heading" >{data.title && parse(data.title)}</h1 >
                            <div className = "top-component-data">
                              <div className="location">
                              <img src={locationIcon} alt="arrow" width="24" height="34" />
                                {data.city.name}, {data.country.name} </div>
                              <div className="company">
                                <img src={arrowLocIcon} alt="location"  width="23" height="34"/>
                               {data.client.name}</div>
                            </div>
                         </div>

                        <div className = "top-component-media" >
                          <picture>
                            <source
                            media="(max-width: 767px)"
                            srcSet={staticImages[imageFormatName][1].src}
                          />
                          <img
                          src={staticImages[imageFormatName][1].src}
                            className = "job-image"
                            alt={alternativeText}
                            maxwidth={`${formats[imageFormatName][1].width}px`}
                            maxheight={`${formats[imageFormatName][1].height}px`}
                          />
                          </picture>
                        </div>
               </div>
            </div>
}

export default JobTop
