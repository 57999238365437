import React from "react"
import "./JobBody.scss"
import parse from "html-react-parser"

const JobBody = ({ data, job_page }) => {
  return <section className="job-data-container">
          {job_page ? (
          <>
            <h2 className="job-body-title">{data.body_title && parse(data.body_title)}</h2>
              <div className="job-card">
                <div className = "job-card-body" >
                  <div className ="content">
                  {/* html from body field */}
                  {data.body && parse(data.body)}
                </div>
               </div>
              </div>
            </>

          ) : (
          <>
              <div className="job-card">
               <div className = "job-card-body" >
                 <div className ="content">
                  {/* html from body field */}
                  {data.body && parse(data.body)}
                </div>
                </div>
               </div>
          </>
          )}
          </section>
}

export default JobBody
