import React from "react"
import parse from "html-react-parser"


const CompanyBody = ({ data, job_page }) => {
  return <section className = "company-data-container" >
          {job_page ? (
          <>
            <h3 className="company-data-title">{data.company_section_title && parse(data.company_section_title)}</h3>
            <div className="company-data">
              <div className = "company-content" >
                {data.body_intro && parse(data.body_intro)}
              </div>
              <a className="btn btn-outline-apply"  href={`mailto:${"hello@dzemo.com"}`}>Apply</a>
            </div>
            </>

          ) : (
          <>
           <div className="company-data">
             <div className = "company-content" >
             {data.body_intro && parse(data.body_intro)}
            </div>
            </div>
          </>
          )}
          </section>

}

export default CompanyBody
