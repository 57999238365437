import React ,{ useMemo }from "react"
import { Layout } from "../../layouts"
import "./JobPage.scss"
import gifImage from "../../assets/images/gif-images/gif-big.svg"
import JobTop from "../../components/JobTop/JobTop"
import JobBody from "../../components/JobBody/JobBody"
import CompanyBody from "../../components/CompanyBody/CompanyBody"
import { graphql } from "gatsby"
import { localizeDataNodes, localizeStaticNodes } from "../../utils/localizeDataNodes"



const JobPage = ({ pageContext: { jobPost, lang, companies, routePrefix }, data, location }) => {
    const { allDzemoApiJobs } = useMemo(
        () => localizeDataNodes(data, lang),
        [data, lang]
      )

  return (
    <Layout location={location} lang={lang} pageData={jobPost} companies={companies} routePrefix={routePrefix}>

      <div className="job-page">

          <JobTop data={jobPost[lang]}  imageFormatName={"normal"}/>

          <JobBody data={jobPost[lang]} job_page/>

          <div className="gif-content">
              <img src={gifImage} alt="gif" width="414" height="81" />
          </div>

           <CompanyBody data={jobPost[lang]} job_page/>
        </div>
    </Layout>

  )
}

export default JobPage


export const query = graphql `
  query JobPageQuery {
    allDzemoApiJobs(sort: { fields: en___created_at, order: DESC }) {
      nodes {
        en {
          id
          name {
            name
            name_de
          }
          body
          client {
            name
            name_de
            logo {
              width
              height
              url
              name
            }
          }
          country {
            name
            name_de
          }
          city {
            name
            name_de
          }
          body_intro
          body_title
          company_section_title
          path
          image {
            alternativeText
            ext
            caption
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                name
                width
                url
                size
              }
            }
          }
          title
        }
        de {
          id
          name {
            name
            name_de
          }
          body
          client {
            name
            name_de
            logo {
              width
              height
              url
              name
            }
          }
          country {
            name
            name_de
          }
          city {
            name
            name_de
          }
          body_intro
          body_title
          company_section_title
          path
          image {
            alternativeText
            ext
            caption
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                name
                width
                url
                size
              }
            }
          }
          title
        }
      }
    }
  }
`
